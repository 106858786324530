import gql from '../../_snowpack/pkg/graphql-tag.js';
export const GET_TENANT_LANGUAGES = gql`
  query GET_TENANT_LANGUAGES($id: ID!) {
    tenant {
      get(id: $id) {
        availableLanguages {
          code
          name
          system
        }
      }
    }
  }
`;